import { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaEllipsis } from 'react-icons/fa6';
import { LabelDefinition, Strategy } from '@api/types';
import { Button, SubmitButtons } from '@components/shared/Buttons';
import { Dropdown } from '@components/shared/Dropdown';
import { Dialog } from '@components/shared/Dialog';

interface LabelDefinitionEdit extends LabelDefinition {
  delete?: boolean;
}

const LabelDefinitions = ({
  definition,
  editLabel
}: {
  definition: LabelDefinition;
  editLabel: (p: any) => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(
    definition.description
  );
  const [exceptions, setExceptions] = useState<Array<string> | string>(
    definition?.exceptions || ''
  );

  const submitLabel = (del?: boolean) => {
    const payload = {
      label: definition.label,
      description,
      exceptions: exceptions,
      delete: del || false
    };
    editLabel(payload);
    setShowDelete(false);
    setEdit(false);
  };

  const handleLabel = (option: string) => {
    if (option === 'Edit') {
      setOpen(true);
      setEdit(true);
    }
    if (option === 'Delete') {
      setShowDelete(true);
    }
  };

  const rowSpanClass = edit ? 'row-span-6' : 'row-span-5';

  if (definition)
    return (
      <div
        className={`h-min cursor-pointer flex items-center flex-grow flex-col ${open ? rowSpanClass : ''}`}
        key={`${definition.label}--edit`}
      >
        <Button
          style={`flex flex-row justify-between border-b border-neutral-300 px-3 py-2 w-full ${open ? 'border-none' : 'border-b'}`}
          onClick={() => definition && setOpen(!open)}
          data-testid={`${definition.label}--edit`}
        >
          <div
            className={`line-clamp-1 font-semibold text-md ${open ? 'line-clamp-2' : ''}`}
          >
            {definition.label}
          </div>
          <div className="flex flex-row gap-3 relative">
            <Dropdown
              buttonStyle="border rounded-[6px] px-1 hover:bg-transparent hover:border-neutral-200 flex justify-center items-center"
              listStyle="absolute z-40 border bg-custom-bg border-neutral-200 w-24 rounded-sm overflow-auto"
              itemStyle="hover:bg-transparent hover:text-cta"
              title={<FaEllipsis size={15} />}
              list={['Edit', 'Delete']}
              onAction={handleLabel}
              arrow={false}
            />

            {!open ? <FaChevronDown size={15} /> : <FaChevronUp size={15} />}
          </div>
        </Button>
        {open && (
          <div
            className={`px-3 pt-2 ${edit ? 'max-h-[292px]' : 'max-h-[232px'} overflow-y-auto flex flex-col w-full cursor-default`}
          >
            <div className="grid grid-cols-4 min-h-full">
              <div>Description</div>
              <textarea
                className="col-span-3 border border-neutral-300 rounded-md p-3 mb-3 bg-fg-light ml-2 resize-none focus:outline-none h-32"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled={!edit}
              />
              <div>Exceptions</div>
              <textarea
                className="col-span-3 border border-neutral-300 rounded-md p-3 bg-fg-light ml-2 resize-none focus:outline-none h-24"
                value={exceptions}
                onChange={(e) => setExceptions(e.target.value)}
                disabled={!edit}
                placeholder="..."
              />
            </div>
            {edit && (
              <div className="flex gap-2 justify-end mt-4">
                <SubmitButtons
                  onSubmit={() => submitLabel(false)}
                  onClose={() => setEdit(false)}
                  submitLabel={'Save'}
                  isLoading={false}
                />
              </div>
            )}
          </div>
        )}
        <Dialog show={showDelete} close={() => setShowDelete(!showDelete)}>
          <h4 className="mb-3">Delete label</h4>
          <div className="text-md font-medium">
            Are you sure you want to delete this label?
          </div>
          <div className="flex gap-2 mt-6 justify-end">
            <SubmitButtons
              onClose={() => setShowDelete(!showDelete)}
              onSubmit={() => submitLabel(true)}
              submitLabel="Confirm"
              isLoading={false}
            />
          </div>
        </Dialog>
      </div>
    );
};

const FixedLabels = ({ labels }: { labels: string[] }) => (
  <div
    className="mb-4 grid gap-4 pt-2 mt-3"
    style={{
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
    }}
  >
    {labels?.map((label: string) => (
      <div
        key={`${label}--list`}
        className="border-b border-neutral-300 px-3 py-2 w-full font-medium overflow-auto"
      >
        {label}
      </div>
    ))}
  </div>
);

const Labels = ({
  strategy,
  editLabel
}: {
  strategy: Strategy;
  editLabel: (p: LabelDefinitionEdit) => void;
}) => {
  if (strategy?.options?.label_definitions)
    return (
      <div
        className="mb-4 grid gap-4 pt-2"
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(480px, 1fr))'
        }}
      >
        {strategy.options.label_definitions?.map(
          (definition: LabelDefinition) => (
            <LabelDefinitions
              definition={definition}
              editLabel={editLabel}
              key={`${definition?.label}`}
            />
          )
        )}
      </div>
    );
  if (strategy?.labels)
    return (
      <FixedLabels
        labels={strategy.labels}
        key={`${strategy.labels}--${strategy.slug}`}
      />
    );
};

export { Labels };
