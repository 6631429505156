import { useEffect, useState } from 'react';
import { useUser } from '@context/UserProvider';
import { useApiService } from '@api/services';
import '@components/style.css';
import { useDate } from '@context/DateProvider';
import { Doughnut } from 'react-chartjs-2';
import { Dropdown } from '@components/shared/Dropdown';
import { rootCausesList } from '@src/constants';
import { DateTime } from 'luxon';
import {
  capitalizeFirstLetter,
  getModeratorEmail,
  getFullNameFromEmail
} from '@utils/string';
import { Loader } from '@components/shared/Loader';
import { FaFileExport } from 'react-icons/fa6';
import csvDownload from 'json-to-csv-export';
import { Button } from '@components/shared/Buttons';

type pageDataType = {
  data?: Array<{
    name: string;
    metric: number;
  }>;
};

export const getPercentage = (num?: number, total?: number) =>
  parseFloat((((num ?? 0) / (total || 1)) * 100).toFixed(2));

const QualityMetrics = () => {
  const { moderators } = useUser();
  const { useQualityMetrics } = useApiService();
  const { startDate, endDate } = useDate();
  const [filter, setFilter] = useState<string>('Accuracy per moderator');
  const [pageData, setPageData] = useState<pageDataType>({});

  const formatDate = (date: DateTime) => {
    return date?.toString()?.slice(0, 10);
  };

  const { data: qualityMetrics, isFetched } = useQualityMetrics({
    startDate: formatDate(startDate),
    endDate: formatDate(endDate)
  });

  useEffect(() => {
    if (qualityMetrics)
      if (filter === 'Accuracy per moderator') {
        setPageData({
          data: qualityMetrics.moderatorAccuracies
            ?.map((slice) => ({
              name:
                slice.moderator !== '-'
                  ? getFullNameFromEmail(
                      getModeratorEmail(slice.moderator, moderators)
                    )
                  : 'modbot',
              metric: getPercentage(slice.passed, slice.checks)
            }))
            ?.sort((a, b) => a.metric - b.metric)
        });
      } else {
        setPageData({
          data: qualityMetrics.rootCauses
            ?.map((slice) => ({
              name:
                capitalizeFirstLetter(
                  rootCausesList?.find(
                    (cause) => cause?.code === slice.rootCause
                  )?.name
                ) || 'Not found',
              metric: getPercentage(slice.failed, qualityMetrics.failed)
            }))
            ?.sort((a, b) => b.metric - a.metric)
        });
      }
  }, [filter, qualityMetrics]);

  const data = {
    labels: ['Failed', 'Passed'],
    datasets: [
      {
        data: [qualityMetrics?.failed, qualityMetrics?.passed],
        backgroundColor: ['rgb(228 230 232)', 'rgb(99 102 241)']
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    }
  };
  const dataToConvert = {
    data: pageData?.data,
    filename: `${
      filter === 'Accuracy per moderator'
        ? `QA report moderator`
        : `QA report causes`
    } (${formatDate(startDate)} to ${formatDate(endDate)})`,
    headers: ['name', 'percentage']
  };

  const exportCsv = () => dataToConvert && csvDownload(dataToConvert as any);

  if (!qualityMetrics?.checks) return null;
  return (
    <div className="roundedContainer h-[350px] min-w-[33%] flex flex-col p-3 overflow-visible w-full relative">
      <div className="flex flex-col xl:flex-row justify-between relative mb-3">
        <h4>Quality Assurance</h4>
        <Dropdown
          title={filter}
          list={['Accuracy per moderator', 'Distribution of causes']}
          onAction={setFilter}
          mainClass="p-0 max-w-max bg-custom-bg"
          buttonStyle="rounded-sm border border-border p-2 hover:bg-transparent hover:border-border"
          listStyle="absolute z-40 border bg-custom-bg border-border w-fit-content rounded-sm overflow-auto"
          itemStyle="hover:bg-transparent hover:text-cta"
        />
      </div>

      <div className="flex flex-row items-center justify-evenly mt-2 h-full">
        <div className="max-w-[50%] flex flex-col items-start">
          {isFetched && (
            <Doughnut
              data={data}
              options={options}
              className="my-2 w-auto max-h-[100px]"
            />
          )}
          <div className="font-base text-sm">Accuracy</div>
          <div className="font-semibold text-3xl flex flex-col">
            {getPercentage(qualityMetrics?.passed, qualityMetrics?.checks)} %
            <div className="-mt-1 text-xs">Pass</div>
          </div>
          <div className="font-base text-sm flex flex-col">
            {qualityMetrics?.checks}
            <div className="-mt-1 text-xs">Total checked</div>
          </div>
        </div>
        <div className="flex flex-col gap-3 w-1/2 border-l border-border max-h-[200px] xl:max-h-[260px] overflow-auto h-full">
          <div className="my-auto">
            {pageData ? (
              pageData?.data?.map(({ name, metric }) => (
                <div key={name} className="flex flex-col pl-[10%] pb-3">
                  <span className="text-sm line-clamp-1 -mb-0.5">{name}</span>
                  <span className="font-bold text-base">{metric} %</span>
                </div>
              ))
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
      <div className="absolute bottom-1 right-1">
        <Button
          style="relative cursor-pointer"
          onClick={exportCsv}
          tooltip="Export Csv"
        >
          <FaFileExport size={15} className="text-primary" />
        </Button>
      </div>
    </div>
  );
};

export default QualityMetrics;
