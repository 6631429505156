import { Button } from '@components/shared/Buttons';
import { DropdownButton } from '@components/shared/Dropdown';
import { DropdownProp, DropdownOptions } from '@typeDef/Shared';
import { useEffect, useRef, useState } from 'react';

const Dropdown = ({
  title,
  options,
  onSelect,
  optionsSelected,
  isAdmin = true,
  align
}: DropdownProp) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    open
      ? document.addEventListener('mousedown', handleClickOutside)
      : document.removeEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  if (!options.length || !isAdmin) return null;

  const onSelectItem = (option: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = option.target;
    optionsSelected.has(value)
      ? optionsSelected.delete(value)
      : optionsSelected.add(value);

    onSelect(new Set(optionsSelected as Set<string>));
  };

  const selectAll = () =>
    onSelect(new Set(options.map((x: DropdownOptions) => x.id.toString())));

  const clear = () => onSelect(new Set());

  return (
    <div
      className="bg-custom-bg border border-border rounded-md relative items-center flex"
      ref={dropdownRef}
    >
      <DropdownButton
        onClick={() => setOpen(!open)}
        title={title}
        open={open}
        buttonStyle="px-2"
      />
      {open && (
        <div
          className={`${align} absolute border border-border bg-custom-bg rounded-md p-2 z-[1000] top-10 w-max max-w-[500px]`}
        >
          <Button style="link py-1" onClick={selectAll}>
            Select All
          </Button>
          <Button style="link py-1" onClick={clear}>
            Clear
          </Button>

          <div className="dropdown-scroll flex flex-col gap-1">
            {options.map((option: DropdownOptions) => (
              <label
                className="ml-2.5 flex flex-row hover:cursor-pointer"
                id="dropdownItem"
                key={option.id.toString()}
                data-testid="dropdownOption"
              >
                <input
                  type="checkbox"
                  value={option.id.toString()}
                  checked={optionsSelected.has(option.id.toString())}
                  onChange={onSelectItem}
                  className="mr-2.5"
                />
                <span className="link p-0 line-clamp-1 w-full">
                  {option.name}
                </span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { Dropdown };
