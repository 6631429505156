// This component expect the following prop
// [
// [{ header: 'col1', val: 'val1' }, { header: 'col2', val: 'val2' }],
// [{ header: 'col1', val: 'valA' }, { header: 'col2', val: 'valB' }],
// ]
//

import { TableType, TableItem } from '@typeDef/Shared';
import { Loader } from '@components/shared/Loader';
import '@components/style.css';

const Table = ({
  isLoading = false,
  list,
  tdStyle,
  tbStyle,
  thStyle,
  children,
  hasMultiHeader
}: TableType) => {
  if (isLoading) return <Loader />;

  if (!list?.length) return <h4 className="displayCenter">No data found</h4>;

  return (
    <table className="w-full">
      <thead className="bg-custom-bg h-[72px]">
        {children}
        <tr>
          {list[0]?.map((x: TableItem, i: number) => (
            <th
              key={i}
              className={`text-left sticky pl-3 font-bold bg-custom-bg w-12 ${thStyle} border-collapse border-r border-b border-border ${
                i === 0
                  ? 'left-0 zIndexTop bg-custom-bg tableFirst'
                  : 'headerBorderB'
              } ${hasMultiHeader ? 'top-12' : 'top-0'}`}
            >
              {x.header}
            </th>
          ))}
        </tr>
      </thead>

      <tbody className={`${tbStyle}`}>
        {list?.map((row: TableItem[], rowIndex: number) => (
          <tr key={`tr${rowIndex}`} className="h-12 tableRow">
            {row?.map((cell: TableItem, colIndex: number) => (
              <td
                key={`td${colIndex}-${cell.val}`}
                className={`${
                  colIndex === 0
                    ? 'sticky left-0 z-40 bg-custom-bg tableFirstCol'
                    : ''
                } pl-3 border-collapse border-r border-b border-border`}
              >
                <div className={tdStyle}>{cell.val}</div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export { Table };
