import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
import { SankeyController, Flow } from 'chartjs-chart-sankey';
import { PolicyMatch } from '@api/types';

ChartJS.register(SankeyController, Flow);

const colorsInit = [
  'rgb(209 250 229)',
  'rgb(0 76 151)',
  'rgb(99 102 241)',
  'rgb(16 185 129)',
  'rgb(0 126 252)',
  'rgb(255 195 80)',
  'rgb(230 37 58)'
];

const SankeyChart = ({ matches }: { matches?: PolicyMatch[] }) => {
  if (!matches?.length) return null;

  const options = {
    responsive: true,
    maintainAspectRatio: false
  };

  const normaliseData = matches.map((p: PolicyMatch) => ({
    from: `${p.potentialViolation.name} `,
    to: p.violation.name,
    flow: p.count
  }));

  const getColors = () => {
    const multiplier = normaliseData.length / colorsInit.length;
    return Array(Math.ceil(multiplier)).fill(colorsInit).flat();
  };

  const colors = getColors();

  const sankeyData = {
    datasets: [
      {
        data: normaliseData,
        colorFrom: (x: any) => colors[x.dataIndex],
        colorTo: (x: any) => colors[x.dataIndex]
      }
    ]
  };
  return (
    <div>
      <div className="text-xl font-semibold mb-6">
        Flagged vs Actual policy violation
      </div>
      <div className="h-96 border border-border rounded-md overflow-auto p-6 bg-custom-bg">
        <Chart type="sankey" data={sankeyData} options={options} />
      </div>
    </div>
  );
};

export { SankeyChart };
