import { Button } from '@components/shared/Buttons';
import { useNavigate } from 'react-router-dom';

type HistoryCardProps = {
  url: string;
  title: string;
  total: number;
  children?: any;
  setShow?: (val: boolean) => void;
};

const UserHistoryCard = ({
  url,
  title,
  total,
  children,
  setShow
}: HistoryCardProps) => {
  const navigate = useNavigate();

  const onClick = () => {
    setShow ? navigate(url) : window.open(url, '_blank');

    setShow?.(false);
  };

  return (
    <Button
      style="py-1 hover:bg-highlight cursor-pointer rounded-sm"
      onClick={onClick}
    >
      <div className="w-full">
        <div className="flex gap-2 items-center font-bold">
          <div className="h-8 w-8 bg-bg-3 text-lg rounded-sm flex items-center justify-center">
            {total}
          </div>
          <div className="text-md">{title}</div>
        </div>
        {children}
      </div>
    </Button>
  );
};

export { UserHistoryCard };
