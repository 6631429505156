import { useApiService } from '@api/services';
import { Button, SubmitButtons } from '@components/shared/Buttons';
import { Dialog } from '@components/shared/Dialog';
import { useEffect, useState } from 'react';
import { FaChevronLeft, FaPlus } from 'react-icons/fa6';
import { useUser } from '@context/UserProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '@components/shared/Header';
import { LabelDefinition, Strategy } from '@api/types';
import { Loader } from '@components/shared/Loader';
import { useHotkeys } from 'react-hotkeys-hook';
import { useAnalytics } from '@context/AnalyticsProvider';
import { KeywordChip } from '@components/Settings/Strategies/KeywordChip';
import { StrategyEdit } from '@components/Settings/Strategies/StrategyEdit';
import { Labels } from '@components/Settings/Strategies/strategyLabels';
import { marked } from 'marked';

interface LabelDefinitionEdit extends LabelDefinition {
  delete?: boolean;
}

const processInput = (text: string) =>
  text.includes(',')
    ? text.split(',').map((word) => word.trim())
    : [text.trim()];

const StrategyCard = () => {
  const { sendEvent } = useAnalytics();
  const { strategyId } = useParams();
  const { user } = useUser();
  const { useStrategies } = useApiService();
  const {
    data: strategies,
    refetch: refetchStrategies,
    isPending: isFetchingStrategies
  } = useStrategies();
  const navigate = useNavigate();
  const [strategy, setStrategy] = useState<Strategy | undefined>(undefined);
  const { editStrategy, postLabelDefinition } = useApiService();
  const [strategyPayload, setStrategyPayload] = useState<any>({});
  const [showAddLabel, setShowAddLabel] = useState<boolean>(false);
  const [newLabelName, setNewLabelName] = useState<string>('');
  const [newKeywords, setNewKeywords] = useState<Set<string>>(new Set());
  const [keywordInput, setKeywordInput] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [exceptions, setExceptions] = useState<string>('');

  useHotkeys('enter', () => showAddLabel && buildKeywords(keywordInput));

  useEffect(() => {
    if (strategyId && strategies) {
      const strategyFound = strategies?.find(
        (s: Strategy) => s.id === parseInt(strategyId)
      );
      setStrategy(strategyFound);
      const { label_keywords, label_definitions } =
        strategyFound?.options || {};

      if (label_keywords) {
        const keywordMap = new Map(
          Object.entries(label_keywords).map(([key, value]) => [
            key,
            new Set(value as any)
          ])
        );
        setStrategyPayload(keywordMap);
      }

      if (label_definitions) {
        const definitionMap = new Map(
          label_definitions.map((def: LabelDefinition) => [
            def.label,
            {
              label: def.label,
              description: def.description,
              exceptions: def.exceptions
            }
          ])
        );
        setStrategyPayload(definitionMap);
      }
    }
  }, [strategyId, strategies]);

  const onSubmit = () => {
    editStrategy.mutate(
      {
        LabelKeywords: Object.entries(Object.fromEntries(strategyPayload)).map(
          ([label, keywords]) => ({
            label,
            keywords: [...keywords]
          })
        ),
        strategy_id: strategy?.id
      },
      {
        onSuccess: async () => refetchStrategies()
      }
    );
  };

  useEffect(() => {
    (editStrategy?.isSuccess || postLabelDefinition?.isSuccess) &&
      setShowAddLabel(false);
  }, [editStrategy?.isSuccess, postLabelDefinition?.isSuccess]);

  const buildKeywords = (keyword: string) => {
    const splitKeyword = processInput(keyword);
    const keywords = new Set(newKeywords);
    splitKeyword.map((word) => {
      if (!keywords.has(word) && word.length > 1) {
        keywords.add(word);
        setKeywordInput('');
      } else {
        keywords.delete(word);
      }
    });
    setNewKeywords(keywords);
  };

  const addLabel = () => {
    const label = newLabelName.trim().toLowerCase().replace(/\s+/g, '_');
    strategyPayload?.set(label, newKeywords);
    sendEvent('clickEvent', { title: `added ${label} label` });
    onSubmit();
  };

  const editLabelDefinition = (payload: LabelDefinitionEdit) => {
    if (payload.delete) {
      strategyPayload.delete(payload.label);
    } else {
      strategyPayload.set(payload.label, payload);
    }

    submitLabelDefinition(strategyPayload, payload.label);
  };

  const addLabelDefinition = () => {
    strategyPayload.set(newLabelName, {
      label: newLabelName,
      description,
      exceptions
    });

    submitLabelDefinition(strategyPayload, newLabelName);
  };

  const submitLabelDefinition = (
    labelsPayload: Map<string, LabelDefinition>,
    labelUpdated: string
  ) => {
    postLabelDefinition.mutate(
      {
        label_definitions: Object.values(Object.fromEntries(labelsPayload)),
        strategyId
      },
      {
        onSuccess: async () => refetchStrategies()
      }
    );
    sendEvent('clickEvent', {
      title: `edited/added ${labelUpdated} label definition`
    });
  };

  if (isFetchingStrategies) return <Loader />;
  if (!strategy || !strategyPayload) return <div>No strategy data found</div>;

  const isKeywordStrategy = ['text-keyword', 'text-wildcard'].includes(
    strategy?.type
  );

  const addLabelClicked = () => {
    setNewKeywords(new Set());
    setNewLabelName('');
    setDescription('');
    setExceptions('');
    setShowAddLabel(true);
  };

  const isDefinition = Boolean(strategy?.options?.label_definitions);

  return (
    <div key={strategy?.id}>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">Settings</div>
          <div className="flex items-baseline gap-2">
            <h2 className="mb-3 text-[28px] lg:text-[32px]">Strategies</h2>
            <h4 className="font-normal text-[20px] lg:text-[22px]">
              - {strategy?.name}
            </h4>
          </div>
        </div>
      </Header>
      <div className="px-8 pt-8">
        <div className="w-full flex justify-between mb-2">
          <Button
            style="buttonOutlined"
            hiddenTitle="strategiesBackBtn"
            onClick={() => navigate('/settings/strategies')}
          >
            <FaChevronLeft size={15} />
          </Button>
          {(isKeywordStrategy || isDefinition) && !!user?.isUserAdmin && (
            <Button
              onClick={addLabelClicked}
              style="buttonOutlined"
              hiddenTitle="strategiesAddNewLabel"
            >
              Add new label +
            </Button>
          )}
        </div>
        <p className="font-bold mt-4">Labels</p>
        {isKeywordStrategy ? (
          <div
            className="mb-4 grid gap-4 pt-2"
            style={{
              gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))'
            }}
          >
            {strategy?.labels?.map((label) => (
              <StrategyEdit
                key={`${label}--edit`}
                label={label}
                list={strategy?.options?.label_keywords?.[label]}
                strategyPayload={strategyPayload}
                isPending={editStrategy?.isPending}
                isSuccess={editStrategy?.isSuccess}
                onSubmit={onSubmit}
                sendEvent={sendEvent}
              />
            ))}
          </div>
        ) : (
          <Labels strategy={strategy} editLabel={editLabelDefinition} />
        )}
        {strategy?.options?.prompt && (
          <div className="flex flex-col">
            <p className="font-bold my-2">Prompt</p>
            <div
              className="prose max-w-full text-text-white"
              dangerouslySetInnerHTML={{
                __html: marked.parse(strategy?.options?.prompt)
              }}
            />
          </div>
        )}
      </div>
      <Dialog show={showAddLabel} close={() => setShowAddLabel(!showAddLabel)}>
        <h4 className="mb-3">Add new label</h4>
        <div className="text-md font-medium">
          Ensure you format your labels consistently, e.g. Self Harm would
          become self_harm
        </div>
        <h6 className="mt-3 mb-1">Label</h6>
        <input
          className="w-full mb-3 roundedContainer focus:outline-none"
          placeholder="New label..."
          onChange={(e) => setNewLabelName(e.target.value)}
        ></input>
        {isDefinition && (
          <>
            <h6 className="my-1">Exceptions</h6>
            <textarea
              className="flex-grow resize-none focus:outline-none bg-custom-bg roundedContainer w-full h-20"
              placeholder="Add exceptions..."
              onChange={(e) => setExceptions(e.target.value)}
              value={exceptions}
            />
            <h6 className="my-1">Description</h6>
            <textarea
              className="flex-grow resize-none focus:outline-none bg-custom-bg roundedContainer w-full h-32"
              placeholder="Add description..."
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </>
        )}
        {!isDefinition && (
          <>
            <h6 className="my-1">Keywords</h6>
            <div className="flex flex-row gap-2 mb-3">
              <div className="flex gap-2 flex-wrap roundedContainer w-full items-center min-h-10">
                {Array.from(newKeywords).map((keyword) => (
                  <KeywordChip
                    keyword={keyword}
                    style="h-7"
                    onClick={buildKeywords}
                    key={`${keyword}--list`}
                  />
                ))}
                <input
                  className="flex-grow focus:outline-none bg-custom-bg"
                  placeholder="add keyword..."
                  onChange={(e) => setKeywordInput(e.target.value)}
                  onKeyDown={({ key }) =>
                    key === 'Enter' && buildKeywords(keywordInput)
                  }
                  value={keywordInput}
                ></input>
                <Button
                  onClick={() => buildKeywords(keywordInput)}
                  style="text-cta max-h-min text-xl flex justify-center"
                >
                  <div>
                    <FaPlus size={21} />
                  </div>
                </Button>
              </div>
            </div>
          </>
        )}
        <div className="flex gap-2 mt-6 justify-end">
          <SubmitButtons
            onClose={() => setShowAddLabel(false)}
            onSubmit={isDefinition ? addLabelDefinition : addLabel}
            disabled={(!newKeywords.size && !description) || !newLabelName}
            submitLabel="Confirm"
            isLoading={editStrategy?.isPending}
          />
        </div>
      </Dialog>
    </div>
  );
};

export { StrategyCard };
