import { Table } from '@components/shared/Table';
import { PolicyMetrics, PoliciesMetrics } from '@api/types';
import { Doughnut } from 'react-chartjs-2';
import { getPercentage, getRate } from '@components/Dashboard/Workflow';

const normaliseIncidents = (policy: any): PolicyMetrics => {
  const { evaluations, reports, open } = policy;
  const excess = evaluations + reports - open;

  return {
    ...policy,
    evaluations: evaluations - excess / 2,
    reports: reports - excess / 2
  };
};

const Incidents = ({
  policiesMetrics
}: {
  policiesMetrics?: PoliciesMetrics;
}) => {
  const normalizedIncidents = policiesMetrics?.policies
    ?.map(normaliseIncidents)
    .map((policy: PolicyMetrics) => [
      {
        header: 'Policy',
        val: `${policy?.policy.code} - ${policy?.policy.name}`
      },
      {
        header: 'Total incidents',
        val: policy.open
      },
      {
        header: 'AI flagged rate',
        val: getPercentage({ val: policy.evaluations, reference: policy.open })
      },

      {
        header: 'Community report rate',
        val: getPercentage({ val: policy.reports, reference: policy.open })
      }
    ]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const incidentData = {
    labels: ['automated', 'community report'],
    datasets: [
      {
        data: [
          getRate({
            val: policiesMetrics?.totals?.evaluations,
            reference: policiesMetrics?.totals?.open
          }),
          getRate({
            val: policiesMetrics?.totals?.reports,
            reference: policiesMetrics?.totals?.open
          })
        ],
        backgroundColor: ['rgb(99 102 241)', 'rgb(245 81 105)']
      }
    ]
  };

  return (
    <div className="w-full">
      <div className="text-xl font-semibold mb-6">Incidents open</div>
      <div className="flex w-full gap-4 h-[19.5rem]">
        <div className="w-1/3 bg-custom-bg rounded-md border border-border p-6 flex gap-4 flex-col">
          <div className="text-lg font-semibold">Incidents</div>
          <div className="flex gap-6 justify-between h-full">
            <div className="w-1/2">
              <Doughnut data={incidentData} options={options} />
            </div>
            <div className="w-1/2 text-sm flex gap-3 flex-col h-full justify-center">
              <div>
                <div>Automated</div>
                <div className="text-cta">
                  {getPercentage({
                    val: policiesMetrics?.totals?.evaluations,
                    reference: policiesMetrics?.totals?.open
                  })}
                </div>
              </div>
              <div>
                <div>Community report</div>
                <div className="text-secondary-500">
                  {getPercentage({
                    val: policiesMetrics?.totals?.reports,
                    reference: policiesMetrics?.totals?.open
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/3 bg-custom-bg rounded-md border border-border overflow-auto">
          <Table
            tdStyle="overflow-hidden text-ellipsis h-6 w-fit"
            list={normalizedIncidents}
          />
        </div>
      </div>
    </div>
  );
};

export { Incidents };
