import { useAnalytics } from '@context/AnalyticsProvider';
import { MdGTranslate } from 'react-icons/md';
import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';

const TextContent = ({
  val,
  isSelectable
}: {
  val: string;
  isSelectable: boolean;
}) => {
  const { sendEvent } = useAnalytics();
  const openLinkInNewTab = (url: string) => {
    window.open(url, '_blank');
  };
  if (val.length > 5000) {
    sendEvent('content > 5000 char', val.length);
  }
  return (
    <div key={val} className="textWrapping py-2 pr-9 pl-2 relative">
      <div className="text-left">
        <div
          className="prose max-w-full text-text-white"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(marked.parse(val) as string)
          }}
        />
        {isSelectable && (
          <MdGTranslate
            size={20}
            className="absolute top-1 right-1 bg-custom-bg rounded-sm p-2 mb-2 w-8 h-8 shadow-md cursor-pointer"
            onClick={() =>
              openLinkInNewTab(
                `https://translate.google.com/?sl=auto&text=${encodeURIComponent(
                  val.substring(0, 5000)
                )}&op=translate`
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export { TextContent };
